<template>
    <div :class="['card',autoWidth ? 'card-block': '',noslider ? 'no-slider': '',typeClass, isHighlighted, address!=''?'has-address':'no-address']" @click="highlight">

        <div class="card-img">
            <div v-if="icon!==null" :alt="type" class="card-img__icon" v-html="icon"></div>
        </div>

        <div class="card-content icon-card">
            <card-share-button class="float-right" :link="linkTo"/>
            <bookmark-button  :parentClass="'card-body-icon'" :content="content" v-if="content!==null"/>
            <h5 :class="['category', typeClass]">{{ type }}</h5>
            <div v-if="logo!==''" class="image">
                <img :src="logo" alt="Logo">
            </div>

            <h3 class="title">{{ item.title }}</h3>

            <ul class="card-content__list">
                <li v-if="address !== ''">
                    <div class="icon"><span class="material-icons-outlined" aria-hidden="true">place</span></div>
                    <div class="info do-not-translate">
                        <span v-html="address"></span>
                    </div>
                </li>
            </ul>
        </div>

        <router-link :to="linkTo" :target="openNewTab" class="btn btn-more mt-auto card-hover">
            Mehr Informationen
            <span class="material-icons-outlined show-ltr" aria-hidden="true">navigate_next</span>
            <span class="material-icons-outlined show-rtl" aria-hidden="true"
            >navigate_before</span>   
        </router-link>

    </div>
</template>

<script>
    import { getSchoolIcon, getKitaIcon } from '@/utils/lupe-helpers';
    import { getFieldValues, getFirstFieldValue } from '@/utils/helpers';

    export default {
        name: 'BildungCard',
        components: {
            CardShareButton: () => import('@/components/controls/CardShareButton.vue'),
            BookmarkButton: () => import('@/components/controls/BookmarkButton'),

        },
        props: {
            item: {
                type: Object,
                required: true
            },
            autoWidth: {
                type: Boolean,
                default: false,
            },
            noslider: {
                type: Boolean,
                default: false,
            },
            target: {
                type: String,
                default: "list",
            },
            openInNewTab: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            openNewTab(){
                if(this.openInNewTab) {
                    return "_blank";
                }
                return "_self";
            },
            linkTo(){
                if(this.item.content_type_name == "schule"){
                    return "/schule/"+this.item.id;
                }
                else if(this.item.content_type_name == "kita"){
                    return "/kita/"+this.item.id;
                }
                return "";
            },
            type(){
                if(this.item!==null){
                    if(this.item.content_type_name == "schule"){
                        var value = getFirstFieldValue(this.item,'schulart');
                        return value!=null?value:'';
                    }
                    else if(this.item.content_type_name == "kita"){
                        var value = getFirstFieldValue(this.item,'betreuungsart');
                        return value!=null?value:'';
                    }
                }
                return "";
            },
            icon(){
                if(this.item.content_type_name == "schule"){
                    return getSchoolIcon();
                }
                else if(this.item.content_type_name == "kita"){
                    return getKitaIcon();
                }
                return "";
            },
            logo(){
                if(this.item!==null){
                    var logo = getFirstFieldValue(this.item,'logo');
                    if(logo!==null){
                        return this.$backendUrl+logo;
                    }
                }
                return "";
            },
            isHighlighted(){
                if(this.item.hasOwnProperty("highlight") && this.item.highlight == true){
                    return "highlighted";
                }
                return "not-highlighted"
            },
            typeClass(){
                return this.type!==null?this.type.replace(/\s/g, '').replace(/[^A-Za-z0-9\-_]/g, ''):'';
            },
            address(){
                var address = getFieldValues(this.item,'adresse');
                if(address!==null){
                    var gna = "";
                    if(address.hasOwnProperty('street') && address.street!==null){
                        gna = gna+address.street+'<br>';
                    }
                    if(address.hasOwnProperty('zipcode') && address.zipcode!==null){
                        gna = gna+address.zipcode+', ';
                    }
                    if(address.hasOwnProperty('city') && address.city!==null){
                        gna = gna+address.city;
                    }
                    return gna;
                }
                return "";
            }
        },
        methods:{
         getFieldValues,
         getFirstFieldValue, 
         highlight(event){
            if(this.target == "map"){
             this.$emit('highlight',{sender:'card', content: this.item});
         }
     }
 }
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .card {
        display: flex;
        position: relative;
        width: 100%;
        border: none;
        border-radius: 0px;
        margin-right: 15px;
        overflow: visible;
        margin-top: 40px;
        box-shadow: 1px 2px 10px 1px #00000040;

        .card-img__icon{
            margin: 10px;
            fill: $primary;
        }

        &.has-address{
            cursor: pointer;
        }

        &.Gymnasium{
            &.highlighted{
                border: 3px solid $gymnasium;
            }

            .card-img{
                border: 1px solid $gymnasium;
            }

            h5{  
                color: $gymnasium;
            }

            .card-img__icon{  
                fill: $gymnasium;
            }
        }

        &.Realschule{

           &.highlighted{
            border: 3px solid $realschule;
        }

        .card-img{
            border: 1px solid $realschule;
        }

        h5{  
            color: $realschule;
        }

        .card-img__icon{  
            fill: $realschule;
        }
    }

    &.Hauptschule{ 

     &.highlighted{
        border: 3px solid $hauptschule;
    }

    .card-img{
        border: 1px solid $hauptschule;
    }

    h5{ 
        color: $hauptschule;
    }

    .card-img__icon{ 
        fill: $hauptschule;
    }
}

&.Oberschule{

 &.highlighted{
    border: 3px solid $oberschule;
}

.card-img{
    border: 1px solid $oberschule;
}

h5{  
    color: $oberschule;
}

.card-img__icon{ 
    fill: $oberschule;
}
}

&.IntegrierteGesamtschule{

 &.highlighted{
    border: 3px solid $integrierteGesamtschule;
}

.card-img{
    border: 1px solid $integrierteGesamtschule;
}

h5{  
    color: $integrierteGesamtschule;
}

.card-img__icon{ 
    fill: $integrierteGesamtschule;
}
}

&.FrderschulefrkrperlicheundmotorischeEntwicklung{

   &.highlighted{
    border: 3px solid $frderschulefrkrperlicheundmotorischeEntwicklung;
}

.card-img{
    border: 1px solid $frderschulefrkrperlicheundmotorischeEntwicklung;
}

h5{  
    color: $frderschulefrkrperlicheundmotorischeEntwicklung;
}

.card-img__icon{ 
    fill: $frderschulefrkrperlicheundmotorischeEntwicklung;
}
}

&.FrderschulefrgeistigeEntwicklung{

   &.highlighted{
    border: 3px solid $frderschulefrgeistigeEntwicklung;
}

.card-img{
    border: 1px solid $frderschulefrgeistigeEntwicklung;
}

h5{ 
    color: $frderschulefrgeistigeEntwicklung;
}

.card-img__icon{ 
    fill: $frderschulefrgeistigeEntwicklung;
}
}

&.BerufsbildendeSchulen{

 &.highlighted{
    border: 3px solid $berufsbildendeSchulen;
}

.card-img{
    border: 1px solid $berufsbildendeSchulen;
}

h5{  
    color: $berufsbildendeSchulen;
}

.card-img__icon{ 
    fill: $berufsbildendeSchulen;
}
}

&.Grundschule{

 &.highlighted{
    border: 3px solid $grundschule;
}

.card-img{
    border: 1px solid $grundschule;
}

h5{ 
    color: $grundschule;
}

.card-img__icon{ 
    fill: $grundschule;
}
}


&-img {
    position: absolute;
    width: 65px;
    height: 65px;
    border-radius: 100px;
    background-color: $white-color;
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -25px;
    left: 25px;

    img {
        max-width: 40px;
        max-height: 41px;
    }
}

&-content {
    padding: 20px;

    .category {
        color: $primary;
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 14px;
    }

    .time {
        color: $text-light;
    }

    .title {
        font-size: 16px;
        line-height: 24px;
        margin-top: 6px;
        margin-bottom: 12px;
        height: auto;
        min-height: 50px;
    }

    .image {
        img {
            max-height: 80px;
        }
    }

    .desc {
        height: 68px;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 10px;
    }

    &__list {
        display: block;
        list-style: none;

        li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 5px;
            color: $black-color;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            .icon {
                span {
                    font-size: 18px;
                    margin-right: 15px;
                    line-height: 24px;
                }
            }

            .info {
                display: inline-flex;
                flex-direction: column;
            }
        }
    }
}

&:first-child {
    margin-left: 15px;

    @media (max-width: 991px) {
        margin-left: 0px;
    }
}

&-block {
    min-width: 290px;
    max-width: 100%;
    width: 100%;
    margin-right: 0;

    &:first-child {
        margin-left: 0;
    }
}

&.no-slider {
    margin-right: 0;
    margin-left: 15px;
}
}

body[dir="rtl"] {
    .card{
        margin-right: 0;
        margin-left: 15px;

        .card-img {
            left: auto;
            right: 25px;
        }

        .card-content__list {
            li {
                .icon {
                    span {
                        margin-left: 15px;
                        margin-right: 0;
                    }
                }
            }
        }

        &:first-child {
            margin-right: 15px;
        }

        &.card-block {
            margin-left: 0;

            &:first-child {
                margin-right: 0;
            }
        }
    }
}
</style>
